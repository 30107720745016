// src/data.js
import { useEffect, useState } from 'react';
import Papa from 'papaparse';

const useGuestData = () => {
    const [guests, setGuests] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vSfKUGCqVN-3xTiDGhxt1Cfs9lQst3ddEviJkxJ8S8kz9qYEQ56w2zscXdWw1lii7Sk_K1xIRXIl9N0/pub?output=csv');
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value);
            const parsedData = Papa.parse(csv, { header: true }).data;
            setGuests(parsedData);
        };

        fetchData();
    }, []);

    return guests;
};

export default useGuestData;
