// src/Login.js
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

// const SubMessage = styled(Typography)({
//     color: 'black',
//     textAlign: 'center',
//     marginBottom: '20px',
//     marginTop: '450px',
//     textShadow: '0 0 10px rgba(255, 150, 0, 0.5), 0 0 20px rgba(255, 0, 0, 0.7), 0 0 30px rgba(255, 0, 0, 1)',
//     boxShadow: '0 4px 6px rgba(255, 255, 255, 0.1)',
//     background: 'rgba(255, 255, 255, 0.5)',
//     padding: '10px',
//     borderRadius: '10px',
//     display: 'inline-block',
// });

const BackgroundImage = styled('img')({
    position: 'absolute',
    top: '0%',
    left: '50%',
    width: '440px',
    transform: 'translate(-50%, -0%)',
    zIndex: -1,
});

// const GlowingImage = styled('img')({
//     height: '40vh',
//     // boxShadow: '0 0 20px 10px rgba(255, 215, 0, 0.8)', // golden glow effect
//     // borderRadius: '10px', // optional, for a smoother look
// });

// const TextImage = styled('img')({
//     width: '40vw',
//     // boxShadow: '0 0 20px 10px rgba(255, 215, 0, 0.8)', // golden glow effect
//     // borderRadius: '10px', // optional, for a smoother look
// });

function Login({ guests, onSelectGuest }) {
    return (
        <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <BackgroundImage src="/getinfo.png" alt="Background" />
            {/* <GlowingImage src="suma.png" alt="Suma" />
            <TextImage src="Suma_Text.png" alt="Suma" /> */}
            {/* <SubMessage variant="h6">
                Please enter your name to get your travel and stay details.
            </SubMessage> */}
            {guests.length === 0 ? (
                <CircularProgress color="primary" />
            ) : (
                <Autocomplete
                    options={guests}
                    style={{ width: '380px', minWidth: '300px', marginTop: '460px' }}
                    getOptionLabel={(option) => option.Name}
                    renderInput={(params) => (
                        <TextField {...params} label="Enter your name" variant="outlined" fullWidth />
                    )}
                    onChange={(event, value) => onSelectGuest(value)}
                />
            )}
            <Box sx={{ mt: 2 }}>
                <Button variant="contained" size="small" color="success" href='/ai.pdf'>
                    Ahmedbad Bus Info
                </Button>
                <Button variant="contained" size="small" style={{ marginLeft: '10px' }} color="info" href='/bi.pdf'>
                    Pravaas Karyakram
                </Button>
            </Box>
        </Box>
    );
}

export default Login;
