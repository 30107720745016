import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#fff',
            paper: '#ccc',
        },
        text: {
            primary: '#000',
        },
        primary: {
            main: '#0027ff',
        },
        secondary: {
            main: '#ffd700',
        },
        success: {
            main: '#c3a05b',
            contrastText: '#fff',
        },
        info: {
            main: '#fd772e',
            contrastText: '#fff',
        },
        error: {
            main: '#2cd0d0',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        // MuiCssBaseline: {
        //     styleOverrides: {
        //         body: {
        //             backgroundImage: 'url(Theme_bgsm.png)',
        //             backgroundRepeat: 'repeat',
        //             backgroundSize: 'cover',
        //             backgroundPosition: 'center',
        //         },
        //     },
        // },
    },
});

export default theme;
