import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const Container = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    textAlign: 'center',
});

const BackgroundImage = styled('img')({
    position: 'absolute',
    top: '0%',
    left: '50%',
    width: '440px',
    transform: 'translate(-50%, -0%)',
    zIndex: -1,
});

const TextOverlay = styled(Typography)({
    position: 'relative',
    color: '#ff3a3a', // Use the color same as the title color in the image
    textShadow: '0 0 10px rgba(170, 0, 0, 0.7)', // optional: adds shadow for better readability
    margin: '10px 0',
    fontSize: '1.25rem',
});

const TextOverlay2 = styled(Typography)({
    position: 'relative',
    color: '#ff3a3a', // Use the color same as the title color in the image
    textShadow: '0 0 10px rgba(170, 0, 0, 0.7)', // optional: adds shadow for better readability
    margin: '10px 0',
    fontSize: '1.10rem',
});

function GuestCard({ guest, onBack }) {
    return (
        <Container style={{ marginTop: "5px", marginBottom: "5px" }}>
            <BackgroundImage src="/mum1.png" alt="Background" />
            <Box>
                <TextOverlay variant="h4" style={{ marginTop: '180px', marginLeft: '100px' }}>{guest.Name}</TextOverlay>
                <TextOverlay2 variant="h4" style={{ marginTop: '60px', marginLeft: '-40px' }}>{guest.Coach}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '-10px', marginLeft: '-40px' }}>{guest.Seat}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '-10px', marginLeft: '-40px' }}>{guest["Bus Number"]}{guest["Bus Seat"] ? `, Seat: ${guest["Bus Seat"]}` : ""}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '60px', marginLeft: '-40px' }}>{guest["Room Number"]}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '63px', marginLeft: '-40px' }}>{guest["Rtn Coach"]}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '-10px', marginLeft: '-40px' }}>{guest["Rtn Seat"]}</TextOverlay2>
                <TextOverlay2 variant="h4" style={{ marginTop: '-10px', marginLeft: '-40px' }}>{guest["Bus Number"]}{guest["Bus Seat"] ? `, Seat: ${guest["Bus Seat"]}` : ""}</TextOverlay2>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="secondary" size="small" onClick={onBack}>
                        Back
                    </Button>
                    <Button variant="contained" color="success" size="small" style={{ marginLeft: '10px' }} href='/bi.pdf'>
                        Pravaas Karyakram
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default GuestCard;
