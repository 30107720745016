import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Login from './Login';
import GuestCard from './GuestCard';
import useGuestData from './data';
import GuestCardAhm from './GuestCardAhm';

function App() {
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [loaded, setIsLoaded] = useState(false);
  const imageArray = ["/mum1.png", "/ahm1.png", "getinfo.png"];
  const guests = useGuestData();
  const preloadImages = (imageArray) => {
    imageArray.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  const handleSelectGuest = (guest) => {
    setSelectedGuest(guest);
  };

  const handleBack = () => {
    setSelectedGuest(null);
  };

  useEffect(() => {
    preloadImages(imageArray);
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        {!selectedGuest ? (
          loaded ? <Login guests={guests} onSelectGuest={handleSelectGuest} /> :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <img src="/logo512.png" alt="Background" onLoad={() => setIsLoaded(true)} />
            </div>
        ) : (
          // if selectedGuest has the field "Bus Seat Number" then render GuestCardAhm else render GuestCard
          selectedGuest["Bus Seat"] && !selectedGuest["Coach"] ? <GuestCardAhm guest={selectedGuest} onBack={handleBack} /> :
            <GuestCard guest={selectedGuest} onBack={handleBack} />
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
